<template>
  <VCard>
    <template #content>
      <ProgressBar
        class="mb-4"
        :label="$t('app.progress')"
        :percentage="percentage"
      />

      <div class="grid grid-cols-3 gap-2">
        <VLine :label="$t('app.lessons', 2)" :value="completedLessonLabel" />

        <VLine
          :label="$t('app.evaluations', 2)"
          :value="completedEvaluationsLabel"
        />

        <VLine
          :label="$t('app.attestations', 2)"
          :value="completedAttestationLabel"
        />

        <VLine
          :label="$t('app.registration_date')"
          :value="formatDate({ date: resource.registered_on })"
        />

        <VLine
          :label="$t('app.completion_date')"
          :value="formatDate({ date: resource.completed_on })"
        />

        <div />

        <VLine
          :label="$t('app.available_date')"
          :value="formatDate({ date: resource?.available_from })"
        />

        <VLine
          :label="$t('app.approval_date')"
          :value="formatDate({ date: resource?.completed_on })"
        />

        <div />

        <VLine
          :label="$t('app.completion_deadline_days')"
          :value="resource.completion_deadline_days"
        />

        <VLine
          :label="$t('app.certificate_valid_until')"
          :value="formatDate({ date: resource.certificate_valid_until })"
        />

        <div />
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useOptions from "@/composables/useOptions";
// Components
import VCard from "@/components/VCard";
import VLine from "@/components/VLine";
import ProgressBar from "@/components/ProgressBar";

export default {
  components: {
    VCard,
    ProgressBar,
    VLine
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();

    const OBJECT_TYPES = {
      EVALUATION: "Evaluation",
      ATTESTATION: "Attestation",
      LESSON: "Lesson"
    };

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { formatDate } = useDisplay();

    // Computed
    const evaluations = computed(() => {
      return getResource(OBJECT_TYPES.EVALUATION);
    });

    const attestations = computed(() => {
      return getResource(OBJECT_TYPES.ATTESTATION);
    });

    const lessons = computed(() => {
      return getResource(OBJECT_TYPES.LESSON);
    });

    const allOutline = computed(() => {
      return (
        evaluations.value?.length +
        lessons.value?.length +
        attestations.value?.length
      );
    });

    const completedAttestations = computed(() => {
      return getCompletedCount(attestations.value, ALL_OPTIONS.APPROVED.value);
    });

    const completedEvaluations = computed(() => {
      return getCompletedCount(evaluations.value, ALL_OPTIONS.COMPLETED.value);
    });

    const completedLessons = computed(() => {
      return getCompletedCount(lessons.value, ALL_OPTIONS.COMPLETED.value);
    });

    const allCompleted = computed(() => {
      return (
        completedEvaluations.value +
        completedAttestations.value +
        completedLessons.value
      );
    });

    const percentage = computed(() => {
      if (allOutline.value === 0) {
        return 0;
      }

      return (100 * allCompleted.value) / allOutline.value;
    });

    const completedLessonLabel = computed(() => {
      return getLabel(completedLessons.value, lessons.value?.length);
    });

    const completedAttestationLabel = computed(() => {
      return getLabel(completedAttestations.value, attestations.value?.length);
    });

    const completedEvaluationsLabel = computed(() => {
      return getLabel(completedEvaluations.value, evaluations.value?.length);
    });

    const outline = computed(() => {
      return props.resource?.outline ?? [];
    });

    // Methods
    const getLabel = (completed, all) => {
      return `${completed}/${all} ${t("app.completed")}`;
    };

    const getCompletedCount = (items, status) => {
      // eslint-disable-next-line
      return items.filter(item => item.learner_data.some(x => filterBy(x, status))).length;
    };

    const filterBy = (item, status) => {
      return item.status === status;
    };

    const getResource = resource => {
      return outline.value.filter(x => {
        return x.outline_object_type === resource;
      });
    };

    return {
      completedLessonLabel,
      completedAttestationLabel,
      completedEvaluationsLabel,
      percentage,
      formatDate
    };
  }
};
</script>
