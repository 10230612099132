<template>
  <VCard title-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage
          :color="resource?.colour"
          :name="getText(resource?.texts, 'name')"
          class="mr-4"
        />

        <div>
          <div class="label text-xs font-light uppercase">
            {{ $t(`backend.${resource.outline_object_type}`) }}
          </div>

          {{ getText(resource?.texts, "name") }}
        </div>
      </div>
    </template>

    <template #title-actions>
      <VChip
        :text="$t(`app.${currentAttempt?.status}`)"
        :class="getStatusColor(currentAttempt?.status)"
      />
    </template>

    <template #content>
      <slot name="content" />
    </template>

    <template v-if="$slots.actions" #actions>
      <slot name="actions" />
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import useOptions from "@/composables/useOptions";
// Components
import VCard from "@/components/VCard";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";

export default {
  components: {
    VCard,
    VChip,
    VImage
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { getText } = useTexts();
    const { trimHTML } = useDisplay();
    const { getStatusColor } = useColor();

    // Computed
    const data = computed(() => {
      return props.resource?.learner_data ?? [];
    });

    const attribute = computed(() => {
      const attributes = {
        Lesson: "completed_on",
        Attestation: "approved_on",
        Evaluation: "learner_completed_on"
      };

      return attributes[props?.resource?.outline_object_type];
    });

    const currentAttempt = computed(() => {
      // THIS DOESN'T TAKE INTO ACCOUNT IF THERE ARE MULTIPLE ATTEMPTS
      // WITH NULL AS ATTRIBUTE VALUE (as it should not happen)
      // You get the current attempt if the attribute is...
      // null (not completed)
      const value = data.value.find(x => x[attribute.value] === null);

      if (value) {
        return value;
      }

      // the latest date (completed)
      const values = data.value.slice().sort((a, b) => {
        const dateA = new Date(a[attribute.value]);
        const dateB = new Date(b[attribute.value]);

        return dateB - dateA;
      });

      return values[0] ?? {};
    });

    return {
      currentAttempt,
      data,
      // useDisplay
      trimHTML,
      // useOptions
      ALL_OPTIONS,
      // useTexts
      getText,
      // useColor
      getStatusColor
    };
  }
};
</script>
