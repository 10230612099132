<template>
  <RegistrationCard :resource="resource">
    <template #content>
      <div class="grid grid-cols-2 gap-2">
        <div class="version col-span-2 -mb-2">
          {{ $t("app.versions") }} {{ resource?.version }}
        </div>

        <div class="col-span-2">
          {{ trimHTML(getText(resource?.texts, "details")) }}
        </div>

        <VLine :label="$t('app.type')">
          <template #value>
            <div class="lesson-type">
              {{ $t(`app.${resource?.type}`) }}
            </div>
          </template>
        </VLine>

        <VLine
          :label="$t('app.duration')"
          :value="
            `${resource?.duration} ${$t(`app.${resource?.duration_type}`)}`
          "
        />

        <VLine :label="$t('app.started_on')">
          <template #value>
            <VDate :date="started_on" />
          </template>
        </VLine>

        <VLine :label="$t('app.completed_on')">
          <template #value>
            <VDate :date="completed_on" />
          </template>
        </VLine>
      </div>
    </template>

    <template #actions>
      <div class="flex justify-end">
        <VButton
          class="btn-primary"
          :label="$t('app.view_lesson')"
          @click="$emit('click:lesson', resource?.id)"
        />
      </div>
    </template>
  </RegistrationCard>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
// Components
import VLine from "@/components/VLine";
import VButton from "@/components/VButton";
import VDate from "@/components/VDate";
import RegistrationCard from "./RegistrationCard";

export default {
  components: {
    VLine,
    VDate,
    VButton,
    RegistrationCard
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Composables
    const { trimHTML } = useDisplay();
    const { getText } = useTexts();

    // Computed
    const attempt = computed(() => props.resource?.learner_data[0]);
    const started_on = computed(() => attempt.value?.started_on ?? "");
    const completed_on = computed(() => attempt.value?.completed_on ?? "");

    return {
      started_on,
      completed_on,
      // useDisplay
      trimHTML,
      // useTexts
      getText
    };
  }
};
</script>
