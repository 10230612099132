<template>
  <RegistrationCard :resource="resource">
    <template #content>
      <div class="grid grid-cols-2 gap-2">
        <div class="col-span-2">
          {{ trimHTML(getText(resource?.texts, "details")) }}
        </div>

        <VLine class="col-span-2" :label="$t('app.files')">
          <template #value>
            <FileItems :files="files" />
          </template>
        </VLine>

        <div class="grid gap-2">
          <VLine :label="$t('app.submit_by')">
            <template #value>
              {{ $t(`app.${submit_by}`) }}
              {{ hasSubmit && submitterName ? `(${submitterName})` : "" }}
            </template>
          </VLine>

          <VLine v-if="hasSubmit" :label="$t('app.requested_on')">
            <template #value>
              <VDate :date="requested_on" />
            </template>
          </VLine>

          <VLine :label="$t('app.submitted_on')">
            <template #value>
              <VDate :date="submitted_on" />
            </template>
          </VLine>

          <VLine
            v-if="hasSubmit"
            :label="$t('app.submitted_by')"
            :value="submittedByName"
          />
        </div>

        <div class="grid gap-2">
          <VLine :label="$t('app.approve_by')">
            <template #value>
              {{ $t(`app.${approve_by}`) }}
              {{ hasApprove && approverName ? `(${approverName})` : "" }}
            </template>
          </VLine>

          <VLine v-if="hasApprove" :label="$t('app.approved_on')">
            <template #value>
              <VDate :date="approved_on" />
            </template>
          </VLine>

          <VLine
            v-if="hasApprove && result"
            :label="$t('app.result')"
            :value="$t(`app.${result}`)"
          />

          <VLine
            v-if="hasApprove"
            :label="$t('app.approved_by')"
            :value="approvedByName"
          />
        </div>
      </div>
    </template>

    <template #actions>
      <div class="flex justify-end">
        <VButton
          class="btn-primary"
          :label="$t('app.view_attestation')"
          @click="$emit('click:attestation', resource?.id)"
        />
      </div>
    </template>
  </RegistrationCard>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";
import useOptions from "@/composables/useOptions";
import useTexts from "@/composables/useTexts";
// Components
import VLine from "@/components/VLine";
import VDate from "@/components/VDate";
import VButton from "@/components/VButton";
import FileItems from "@/components/FileItems";
import RegistrationCard from "./RegistrationCard";

export default {
  components: {
    VLine,
    VDate,
    VButton,
    FileItems,
    RegistrationCard
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Composables
    const { trimHTML } = useDisplay();
    const { getText } = useTexts();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const files = computed(() => props.resource?.files ?? []);
    const attempt = computed(() => props.resource?.learner_data[0]);
    const requested_on = computed(() => attempt.value?.requested_on ?? "");
    const submitted_on = computed(() => attempt.value?.submitted_on ?? "");
    const approved_on = computed(() => attempt.value?.approved_on ?? "");
    const submit_by = computed(() => attempt.value?.submit_by);
    const submitter = computed(() => attempt.value?.submitter ?? {});
    const approve_by = computed(() => attempt.value?.approve_by);
    const approver = computed(() => attempt.value?.approver ?? {});
    const result = computed(() => attempt.value?.result);
    const submitted_by = computed(() => attempt.value?.submitted_by ?? {});
    const approved_by = computed(() => attempt.value?.approved_by ?? {});
    const submitterName = computed(() => {
      const { firstname, lastname } = submitter.value;

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });
    const approverName = computed(() => {
      const { firstname, lastname } = approver.value;

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });
    const approvedByName = computed(() => {
      const { firstname, lastname } = approved_by.value;

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });
    const submittedByName = computed(() => {
      const { firstname, lastname } = submitted_by.value;

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });
    // eslint-disable-next-line
    const hasSubmit = computed(() => submit_by.value !== ALL_OPTIONS.LEARNER.value);
    // eslint-disable-next-line
    const hasApprove = computed(() => approve_by.value !== ALL_OPTIONS.NONE.value);

    return {
      files,
      submit_by,
      approve_by,
      requested_on,
      submitted_on,
      approved_on,
      submitterName,
      approverName,
      approvedByName,
      submittedByName,
      hasSubmit,
      hasApprove,
      result,
      // useDisplay
      trimHTML,
      // useTexts
      getText
    };
  }
};
</script>
