<!-- eslint-disable -->
<template>
  <div>
    <VTitle :title="$t('app.progress')" class="section-title" />

    <div class="grid grid-cols-3 gap-8">
      <div>
        <TrainingHeader
          v-if="resource?.training"
          :training="resource.training"
          @click="onClickRedirectToTraining"
          @click:role="onClickRedirectToRole"
        >
          <template #role="{ role }">
            <div class="cursor-pointer" @click="onClickRedirectToRole(role.id)">
              {{ role.name }}
            </div>
          </template>
        </TrainingHeader>
      </div>

      <div class="col-span-2">
        <ProgressCard :resource="resource" class="mb-8" />

        <component
          v-for="outline in resource.outline"
          :key="`${outline.outline_object_type}-${outline.id}`"
          :is="outline.outline_object_type"
          :resource="outline"
          class="mb-8"
          @click:lesson="onClickLesson"
          @click:attestation="onClickAttestation"
          @click:evaluation="onClickEvaluation"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Components
import VTitle from "@/components/VTitle";
import Attestation from "./Attestation";
import Lesson from "./Lesson";
import Evaluation from "./Evaluation";
import TrainingHeader from "@/views/learning/trainings/TrainingHeader";
import ProgressCard from "./ProgressCard";

export default {
  components: {
    ProgressCard,
    VTitle,
    TrainingHeader,
    Evaluation,
    Attestation,
    Lesson
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const router = useRouter();

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.progress")} - ${t("app.trainings", 2)} - ${t("app.registrations", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource?.learner ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const onClickRedirectToTraining = () => {
      router.push({
        name: "teaching-trainings-update",
        params: {
          id: props.resource.training.id
        }
      });
    };

    const onClickLesson = lessonId => {
      router.push({
        name: "teaching-registrations-trainings-lessons-details",
        params: {
          id: props.resource.id,
          lessonId
        }
      });
    };

    const onClickAttestation = attestationId => {
      router.push({
        name: "teaching-registrations-trainings-attestations-details",
        params: {
          id: props.resource.id,
          attestationId
        }
      });
    };

    const onClickEvaluation = evaluationId => {
      router.push({
        name: "teaching-registrations-trainings-evaluations-details",
        params: {
          id: props.resource.id,
          evaluationId
        }
      });
    };

    const onClickRedirectToRole = id => {
      router.push({
        name: "teaching-roles-update",
        params: { id }
      });
    };

    return {
      onClickRedirectToTraining,
      onClickRedirectToRole,
      onClickLesson,
      onClickAttestation,
      onClickEvaluation,
      documentTitle
    };
  }
};
</script>
