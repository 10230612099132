<template>
  <RegistrationCard :resource="resource">
    <template #content>
      <div class="grid grid-cols-2 gap-2">
        <div class="version col-span-2 -mb-2">
          {{ $t("app.versions") }} {{ resource?.version }}
        </div>

        <div class="col-span-2">
          {{ trimHTML(getText(resource?.texts, "details")) }}
        </div>

        <VLine class="col-span-2" :label="$t('app.type')">
          <template #value>
            <div class="lesson-type">
              {{ $t(`app.${resource?.type}`) }}
            </div>
          </template>
        </VLine>

        <VLine
          class="col-span-2"
          :label="$t('app.duration')"
          :value="
            `${resource?.duration} ${$t(`app.${resource?.duration_type}`)}`
          "
        />

        <VLine
          :label="$t('app.maximum_attempts')"
          :value="resource?.maximum_attempts_per_registration"
        />

        <VLine
          :label="$t('app.interval_between_attempts')"
          :value="resource?.days_till_next_attempt"
        />

        <VLine :label="$t('app.grade')" :value="grade" />

        <VLine :label="$t('app.completed_on')">
          <template #value>
            <VDate :date="completed_on" />
          </template>
        </VLine>
      </div>
    </template>

    <template #actions>
      <div class="flex justify-end">
        <VButton
          class="btn-primary"
          :label="$t('app.view_evaluation')"
          @click="$emit('click:evaluation', resource?.id)"
        />
      </div>
    </template>
  </RegistrationCard>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
// Components
import VLine from "@/components/VLine";
import VDate from "@/components/VDate";
import VButton from "@/components/VButton";
import RegistrationCard from "./RegistrationCard";

export default {
  components: {
    VLine,
    VDate,
    VButton,
    RegistrationCard
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Composables
    const { trimHTML, formatDate } = useDisplay();
    const { getText } = useTexts();

    // Computed
    const attempt = computed(() => props.resource?.learner_data[0]);
    // eslint-disable-next-line
    const completed_on = computed(() => attempt.value?.learner_completed_on ?? "");
    const grade = computed(() => attempt.value?.grade ?? "-");

    return {
      completed_on,
      grade,
      // useDisplay
      trimHTML,
      formatDate,
      // useTexts
      getText
    };
  }
};
</script>
